<template>
<div class="card-box-full">
  <div class="card-box-header">
    Top Domains
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="isRefreshing" class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 15px">
    <span class="sr-only"></span>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-else-if="errorMessage">
    <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorMessage }}</div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-else-if="domains && domains.length > 0">
    <table class="table table-responsive-sm" style="margin-top: 10px; margin-bottom: 0px;">
      <thead>
        <tr style="font-size: 13px; border: 2px solid transparent">
          <th>Domain</th>
          <th style="text-align:right">Times Seen</th>
          <th style="text-align:center">Last Seen</th>
          <th style="text-align:center">Reputation</th>
          <th style="text-align:center">Confidence</th>
          <th style="text-align:center">Overridden</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="domain in domains" style="font-size: 13px" class="clickable-row" @click="clickedDomain(domain)">
          <td>
            <span style="color: #3A9CFC; font-weight: 700">{{ domain.domain }}.{{ domain.tld }}</span>
          </td>
          <td align="right">{{ domain.times_seen.toLocaleString() }}</td>
          <td align="center">
            <span class="badge badge-pill badge-light">
              <timeago :datetime="domain.last_seen"></timeago>
            </span>
          </td>
          <td align="center">
            <div>
              <span v-if="domain.positive_reputation == null" class="badge badge-pill badge-warning">
                Neutral
              </span>
              <span v-else-if="domain.positive_reputation" class="badge badge-pill badge-success">
                Positive
              </span>
              <span v-else class="badge badge-pill badge-danger">
                Negative
              </span>
            </div>
          </td>
          <td align="center">
            <div>
              <span v-if="domain.high_confidence == null">
              </span>
              <span v-else-if="domain.high_confidence" class="badge badge-pill badge-dark">
                High
              </span>
              <span v-else class="badge badge-pill badge-light">
                Low
              </span>
            </div>
          </td>
          <td align="center">
            <div>
              <span v-if="domain.overridden" class="badge badge-pill badge-dark">
                Yes
              </span>
              <span v-else class="badge badge-pill badge-light">
                No
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
</template>

<script>
export default {
  data() {
    return {
      domains: null,
      errorMessage: null,
      isRefreshing: false
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedDomain(domain) {
      this.$router.push({
        path: "/sms-profile/domain/" + encodeURIComponent(domain.domain + "." + domain.tld)
      });
    },
    //--------------------------------------------------------------------------
    refresh() {
      if (this.isRefreshing) {
        return;
      } else {
        this.errorMessage = null;
        this.isRefreshing = true;
      }

      // Perform the request
      var that = this;
      var method = "GET";
      var url = "https://domain-reputation.platform.robocalls.ai/top-domains";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshing = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshing = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshing = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {
      if (!response || !response.top_domains || response.top_domains.length == 0) {
        this.errorMessage = "No domains were returned";
        return;
      }
      this.domains = response.top_domains;
    },
  },
  mounted() {
    this.refresh();
  }
}
</script>
